/* eslint-disable max-len */
import {
  get,
  patch,
  post,
} from '@osrdata/app_core/dist/requests'
import { store } from 'reducers/store'
import { Feedback, GetSimulationsParams } from 'reducers/types'
import { getUsersInfo } from 'reducers/userInfo/thunks'
import { Filter } from 'types/filters'
import { createApiThunk } from 'utils'
import {
  setCommentSeverityList, setCommentStatusList,
  setFeedback,
  setFileData,
  setNetworks, setSchedules, setSelectedSimulation,
  setSimulationsList,
  setVersions,
} from './slices'

const getVersions = () => createApiThunk(
  'simulations/getVersions',
  () => get('/masters/versions/'),
  setVersions,
)

const getNetworks = (versionId: string) => createApiThunk(
  'simulations/getNetworks',
  () => get('/masters/networks/', { versionId }),
  setNetworks,
)

const getSchedules = (networkId: string) => createApiThunk(
  'simulations/getSchedules',
  () => get('/masters/schedules/', { networkId }),
  setSchedules,
)

const getSimulation = (id: string) => createApiThunk(
  'simulations/getSimulation',
  () => get(`/masters/simulations/${id}/`),
  setSelectedSimulation,
)

const postSimulation = (scheduleId: string, name: string) => createApiThunk(
  'simulations/postSimulation',
  () => post('/masters/simulations/', { name, scheduleId }),
  setSelectedSimulation,
)

const createSimulationReplay = (simulationId: string, name: string) => createApiThunk(
  'simulations/createSimulationReplay',
  () => post('/masters/simulations-replay/', { name, originalSimulationId: simulationId }),
  setSelectedSimulation,
)

const postFeedback = (simulationId: string, feedback: Feedback) => createApiThunk(
  'simulations/postFeedback',
  () => post('/masters/comments/', { ...feedback, simulationId }),
  setFeedback,
)

const patchFeedback = (simulationId: string, commentId: string, feedback: Feedback) => createApiThunk(
  'simulations/patchFeedback',
  () => patch(`/masters/comments/${commentId}`, { ...feedback, simulationId }),
  setFeedback,
)

const getSimulationsList = (parameters: GetSimulationsParams) => createApiThunk(
  'simulations/getSimulationsListById',
  () => {
    // eslint-disable-next-line camelcase
    const { page, page_size, ...params } = parameters
    // eslint-disable-next-line camelcase
    return post('/masters/simulations-fetch/', params, { params: { page, page_size } })
  },
  setSimulationsList,
)

const patchSimulationName = (simulationId: string, name: string) => createApiThunk(
  'simulations/patchSimulationName',
  () => patch(`/masters/simulations/${simulationId}/`, { name }),
  setSelectedSimulation,
)

const deleteSimulations = (params: {filters: Filter[]}) => createApiThunk(
  'simulations/deleteSimulations',
  () => post('/masters/simulations-delete/', params),
)
const getCommentStatusList = () => createApiThunk(
  'simulations/getCommentStatusList',
  () => get('/masters/status-choices/'),
  setCommentStatusList,
)
const getCommentSeverityList = () => createApiThunk(
  'simulations/getCommentSeverityList',
  () => get('/masters/severity-choices/'),
  setCommentSeverityList,
)

const exportSimulations = params => createApiThunk(
  'simulations/exportSimulations',
  () => post('/masters/simulations-report/', params, { responseType: 'blob' }),
  setFileData,
)

const getSimulationsUsers = () => createApiThunk(
  'simulations/getSimulationsUsers',
  async () => {
    const response = await get('/masters/users/')
    store.dispatch(getUsersInfo(response.map(u => u.userId)))
    return response
  },
  undefined,
)

export {
  createSimulationReplay, deleteSimulations, exportSimulations, getCommentSeverityList, getCommentStatusList, getNetworks,
  getSchedules,
  getSimulation, getSimulationsList, getSimulationsUsers, getVersions, patchFeedback, patchSimulationName,
  postFeedback, postSimulation,
}
