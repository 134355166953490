/* eslint-disable max-len */
export const terms = {
  Common: {
    loading: 'Chargement',
    welcome: 'Bienvenue',
    or: 'ou',
    cancel: 'Annuler',
    continue: 'Continuer',
    validate: 'Valider',
    disconnect: 'Déconnexion',
  },
  Error: {
    forbidden: {
      appTitle: 'Vous n\'avez pas les droits nécessaires pour accéder à cette application.',
      appSubtitle: 'Veuillez contacter votre administrateur.',
      content: 'Vous n\'avez pas les droits pour accéder à cette ressource. Veuillez réessayer ou contacter votre administrateur.',
      exitButton: 'Retour au portail des applications',
    },
    httpStatus: {
      forbidden: 'Vous n\'avez pas les droits pour effectuer cette action.',
      notFound: 'La ressource demandée n\'existe pas.',
      internalServerError: 'Une erreur est survenue.',
      serverDown: 'Le serveur est indisponible.',
      unknownError: 'Une erreur inconnue est survenue.',
      badRequest: 'Vérifiez les informations que vous avez entrées et réessayez.',
    },
    webSocket: {
      lostConnection: 'Connexion perdue, tentative de reconnexion...',
    },
  },
  Home: {
    columns: {
      titleVersion: 'Choisissez une version',
      titleNetwork: 'Choisissez un plan de voie',
      titleSchedule: 'Choisissez un scénario',
    },
    btn: {
      openSimulation: 'Ouvrir la simulation',
    },
  },
  Comment: {
    writeGeneral: 'Écrivez un commentaire général',
    writeOne: 'Écrire un commentaire',
    selectLogs: 'Sélectionnez les logs concernés par ce commentaire',
    findInHistory: 'Retrouvez le dans “historique des commentaires”',
    criticity: 'Criticité',
    selectedLogs: 'log(s) sélectionné(s)',
    comments: 'Commentaires',
  },
  Tco: {
    closeConfirmation: 'Êtes vous sûr(e) de vouloir fermer le TCO ?',
    runningSimulation: 'La simulation est encore en cours',
    close: 'Fermer le TCO',
    open: 'Ouvrir le TCO',
    prci: 'P R C I',
    name: 'TCO',
  },
  Tpt: {
    open: 'Ouvrir le TPT',
    name: 'TPT',
  },
  Snci: {
    open: 'Ouvrir le SNCI',
    close: 'Fermer le SNCI',
    closeConfirmation: 'Êtes vous sûr(e) de vouloir fermer le SNCI ?',
    name: 'SNCI',
    cantShowLines: "Impossible d'afficher le nombre minimal de 10 lignes de programmation",
    pleaseResize: 'Veuillez agrandir la fenêtre',
  },
  Logs: {
    openInterfaces: 'Ouvrir les écrans :',
  },
  History: {
    confirmDeletion: (number: number) => `Souhaitez-vous vraiment supprimer ${number} simulation${number > 1 ? 's' : ''} ?`,
    replay: 'Rejeu',
  },
  Filter: {
    add: 'Ajouter un filtre',
    remove: 'Supprimer',
    column: 'Colonne',
    operator: 'Opérateur',
    value: 'Valeur',
    operators: {
      contains: 'contient',
      not_contains: 'ne contient pas',
      eq: 'est égal à',
      not_eq: 'n\'est pas égal à',
      notnull: 'n\'est pas vide',
      isnull: 'est vide',
      between: 'est entre',
      in: 'contient',
      not_in: 'ne contient pas',
      gt: 'supérieur à',
      gte: 'supérieur ou égal à',
      lt: 'inférieur à',
      lte: 'inférieur ou égal à',
    },
    columns: {
      userId: 'CP',
      name: 'Titre',
      severity: 'Criticité',
      status: 'Statut',
      createdAt: 'Date',
      versionName: 'Version',
    },
  },
} as const

export default terms
